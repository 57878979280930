@import "../../style/variables";

.validationMessage {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: $black;
  .text {
    display: inline-block;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
  }
  .tooltip {
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 0;
    width: 100%;
  }
}

.validationMessage.Success {
  color: $button-green;
}

.validationMessage.Error {
  color: $error-red;
}
