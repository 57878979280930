@import "../../style/variables";

a.Button,
button.Button {
  position: relative;
  text-align: center;
  font-size: inherit;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
}

a.Button:focus,
button.Button:focus {
  outline: 0;
}

a.Button:active,
button.Button:active {
  outline: 0;
}

a.Button.isDisabled,
button.Button.isDisabled {
  color: $grey-text;
  cursor: default;
}

/* Content */
.loadingIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
}
.errorIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
}

/* Wide */

.Button.GreenWide,
.Button.BlueWide,
.Button.RedWide {
  display: block;
  padding: 15.5px 15px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  border-radius: 6px;
}

.Button.GreenWide.isDisabled,
.Button.BlueWide.isDisabled,
.Button.RedWide.isDisabled,
.Button.BluePill.isDisabled,
.Button.GreenPill.isDisabled,
.Button.GhostPill.isDisabled,
.Button.HeaderMenuOption.isDisabled {
  cursor: default;
  background: #e8e8e8;
  color: #fff;
  border-color: #e8e8e8;
}

.Button.GreenWide {
  background: $button-green;
}

.Button.BlueWide {
  background: $primary;
}

.Button.RedWide {
  background: $error-red;
}

/* Pill */

.Button.BluePill {
  display: block;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid $primary;
  background: $primary;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.Button.GreenPill {
  display: block;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid $button-green;
  background: $button-green;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.Button.GhostPill {
  display: block;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid $body-text;
  color: $body-text;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  &:not(.isDisabled).--green {
    border: 1px solid $button-green;
    color: $button-green;
  }
  &:not(.isDisabled).--white {
    border: 1px solid #fff;
    color: #fff;
  }
}

/* Ghost Button */

.Button.WideGhost {
  display: block;
  padding: 12px 0;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  border: 1px solid currentColor;
  color: currentColor;
}

.Button.WideGhost.isDisabled {
  border: 1px solid #c8cdd0;
  color: #c8cdd0;
}

/* Link */

.Button.BasicLink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  color: $black;
  transition: color 100ms;
}

/* Blue Link */

.Button.BlueLink {
  color: $primary;
  font-weight: 600;
  .loadingIcon {
    right: -28px;
  }
}

/* Red Link */

.Button.RedLink {
  color: #f16464;
  font-weight: 600;
  .loadingIcon {
    right: -28px;
  }
}

/* White Link */

.Button.WhiteLink {
  color: $white;
  font-weight: 600;
  .loadingIcon {
    right: -28px;
  }
}

/* Header Menu Option */

.Button.HeaderMenuOption {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  width: 100%;
  padding: 12px 0;
  background: $primary;
  color: #fff;
  border-radius: 6px;
  text-align: center;
  text-transform: uppercase;
  &.--danger {
    background: $error-red;
  }
}

/* Icon */

.Button.Icon {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  color: currentColor;
  cursor: pointer;
}

/* Icon With Background */

.Button.IconWithBackground {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  transform: translate3d(0, 0, 0);
  cursor: pointer;
}

/* Instagram Image */

.Button.InstagramImage {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 33.33%;
  padding-top: 33.33%;
  border: 1px solid #f2f2f2;
  background: #edeced;
  color: #222222;
  overflow: hidden;
  > img {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 0;
    transform: translateX(-50%);
  }
  &.childFormForceDisplayBlock > form {
    display: block !important;
  }
}

/* Profile Listed */

.Button.ProfileListed {
  border-radius: 6px;
  background: #f0f0f0;
  overflow: hidden;
  display: block;
  /* height: style applied inline to be 60% of viewport */
  transition: 200ms max-height cubic-bezier(0, 1, 0, 1); /* This is to make mobile screen resizes smoother */
  overflow: hidden;
}

.Button.ProfileListedError {
  display: block;
  min-height: 400px;
  overflow: hidden;
}

/* Image Gallery */

.Button.Gallery {
  position: absolute;
  top: 50%;
  height: calc(100% - 134px);
  transform: translateY(-50%);
  cursor: pointer;
  outline: 0;
  z-index: 2;
  width: 20%;
  height: 40%;
}

.Button.Gallery.Left {
  left: 0;
  text-align: left;
  img {
    margin-left: 12px;
  }
}

.Button.Gallery.Right {
  right: 0;
  text-align: right;
  img {
    margin-right: 12px;
  }
}

/* Mobile Filter */

.Button.MobileFilter {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: $body-text;
  padding: 10px 0;
}

.Button.MobileFilter.isActive {
  font-weight: bold;
  color: $primary;
  border-bottom: 1px solid $primary;
}

/* Switch */

.Button.SwitchButton {
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: $body-text;
  background: #fff;
  padding: 4px 0 5px;
  border: 1px solid $border-color;
  border-right: none;
  width: 100%;
  min-height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:first-child {
    border-radius: 6px 0 0 6px;
  }
  &:last-child {
    border-radius: 0 6px 6px 0;
    border-right: 1px solid $border-color;
  }
}

.Button.SwitchButton.isActive {
  background: $primary;
  color: #fff;
  border: 1px solid $primary;
}

/* Tab */

.Button.TabButton {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* color: $body-text; */
  color: #595959;
  background: #fff;
  padding: 9px;
  border: 1px solid $border-color;
  border-right: none;
  width: 100%;

  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
    border-right: 1px solid $border-color;
  }
}

.Button.TabButton.isActive {
  color: $primary;
  font-weight: bold;
  border-color: $primary;
  padding-left: 8px;
  padding-right: 8px;
}

.Button.TabButton.isActive + .Button.TabButton {
  border-left-color: $primary;
}

/* Desktop Filter */

.Button.DesktopFilter {
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  background: #fff;
  padding: 10px 20px 11px;
  border: 1px solid $border-color;
}

.Button.DesktopFilter.isActive {
  background: #10549e;
  color: #fff;
  border: 1px solid #f2f2f2;
}

/* List Item */

.Button.ListItem,
.Button.ListItemDesktop {
  display: block;
  text-align: left;
  width: 100%;
  color: inherit;
  padding: 15px 16px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

.Button.ListItem {
  .loadingIcon {
    right: 16px;
  }
  .errorIcon {
    right: 16px;
  }
}

.Button.ListItemDesktop {
  padding: 10px 16px;
  /* &:hover {
    color: $grey-text;
  } */
  &.isError {
    padding-right: 32px;
  }
}

/* Chat Item */

.Button.ChatItem {
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
  width: 100%;
  height: 70px;
  color: $title-color;
}

.Button.ChatItem.isActive {
  background: $primary;
  color: $white;
}
.Button.ChatItem.isUnread:not(.isActive) {
  background: #f2f9ff;
}
.Button.ChatItem.isSelected:not(.isActive) {
  background: #f2f9ff;
}
.Button.ChatItem.isEditing.isDisabled:not(.isActive) {
  background: #f7f7f7;
}

/* Menu Item */

.Button.MenuItem {
  display: block;
  width: 100%;
  height: 100%;
  padding: 20px;
  text-align: center;
}

/* Menu Item */

.Button.ImageGalleryDot {
  position: relative;
  width: 10px;
  height: 10px;
}

/* Complete Profile */

.Button.CompleteProfile {
  display: block;
  padding: 14px 15px;
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  border-radius: 3px;
  background: $primary;
  color: $white;
}

.Button.--underlined {
  text-decoration: underline;
}

.Button.--rounded {
  border-radius: 46px;
}

.Button.--inline {
  display: inline-block;
  width: auto;
}

.Button.--narrow {
  padding: 6px 7px;
  .loadingIcon {
    width: 100%;
    height: 100%;
    left: 0;
  }
}

.Button.--jiggle {
  animation: jiggle 4s infinite 4s;
}

@media (min-width: $from-tablet) {
  .Button.MobileFilter {
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: $body-text;
    padding: 10px;
  }
  .Button.CompleteProfile {
    display: inline-block;
    padding: 12px 44px;
    font-weight: 600;
    width: auto;
    float: right;
    font-size: 14px;
    line-height: 19px;
    border-radius: 6px;
    background: $primary;
    color: $white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .Button.Gallery {
    width: 80px;
  }
}

@keyframes jiggle {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  1% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  2% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  3% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  4% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  5% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  6% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  7% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  8% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  80% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  90% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
