@import "../../style/variables";

.CryptoPaymentSuccessPopup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    animation: purchase-success-scale-animation 0.3s ease-in-out 0.9s both;
    margin: 0 auto;
    .circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: #4bb71b;
      animation: purchase-success-stroke-animation 0.6s
        cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }
    .check {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: purchase-success-stroke-animation 0.3s
        cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }
  }
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #121212;
    margin-top: 16px;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $body-text;
    margin-top: 16px;
  }
}

@keyframes purchase-success-scale-animation {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes purchase-success-stroke-animation {
  100% {
    stroke-dashoffset: 0;
  }
}
