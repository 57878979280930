@import "../../../../style/variables";

.PopupContent {
  .imageWrapper {
    display: inline-block;
    width: 90px;
    height: 90px;
    .emoj {
      margin-top: 4px;
      font-size: 90px;
    }
    .image {
      margin-top: 16px;
      height: auto;
      width: 100%;
    }
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    margin-top: 16px;
  }
  .children {
    margin-top: 16px;
  }
}
