@import "../../style/variables";

.ToolTip {
  position: relative;
  display: inline-block;
  border: 1px solid #d4d4d4;
  box-shadow: 0px 14px 36px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  background: $white;
  .tail {
    position: absolute;
    top: -6px;
    width: 10px;
    height: 10px;
    border-top: 1px solid #d4d4d4;
    border-left: 1px solid #d4d4d4;
    background: $white;
    transform: rotate(45deg);
    z-index: 2;
  }
  &.TailTypeLeft .tail {
    left: 40px;
    transform: rotate(45deg);
  }
  &.TailTypeRight .tail {
    right: 40px;
    transform: rotate(45deg);
  }
  &.TailTypeCenter .tail {
    left: 50%;
    transform: rotate(45deg);
  }
  .content {
    padding: 10px 20px;
    max-height: calc(100vh - 120px);
    overflow: auto;
  }
}
