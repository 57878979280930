@import "../../../style/variables";

.UnlockFreeChatPopup {
  header {
    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: $body-text;
    }
  }
  section {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: $body-text;
    }
  }
  .iconWrapper {
    width: 112px;
    height: 112px;
    border: 3px solid $border-color;
    border-radius: 50%;
    margin: 16px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button {
    margin-top: 16px;
  }
}
