/* Colors */
$primary: #0094f4;
$secondary: #059aff;
$pink: rgba(255, 23, 98, 0.8);
$error-red: #eb5757;
$button-green: #0cb549;
$white: #fff;
$off-white: #f8f8f8;
$faint-color: #f5f5f5;
$transparent-white: rgba(255, 255, 255, 0.85);
$black: #000000;
$text-color: #555;
$light-grey: #eaeaea;
$text-drop-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
$icon-drop-shadow: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.6));

/* Text */
$title-color: #181a1b;
$body-text: #303637;
$grey-text: #8e8e93;
$placeholder-color: #edeced;

/* Font Families */
$font-default:
  -apple-system,
  system-ui,
  BlinkMacSystemFont,
  Segoe UI,
  Roboto,
  Helvetica Neue,
  Helvetica,
  Arial,
  sans-serif;
$font-emoji: "Apple Color Emoji", "Noto Color Emoji", "Segoe UI Emoji", Times,
  Symbola, Aegyptus, Code2000, Code2001, Code2002, Musica, serif, LastResort;

/* Backgrounds */
$default-background-color: #fff;
$settings-background-color: #f8f8f8;

/* Borders */
$border-collapse: rgba(0, 0, 0, 0.9);
$border-color: #d4d9e2;

/* Shadow */
$box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.05);
$box-shadow-inset: inset 0 40px 0 rgba(0, 0, 0, 0.05);

/* Font Size */
$title-text-size: 28px;
$subtitle-text-size: 20px;
$body-text-size: 14px;

/* Spacing */
$inline-spacing: 8px;

/* Icons */
$icon-size: 20px;
$icon-wrapper-size: 40px;

/* UI Sizes */
$header-height: 50px;

/* Focus */
$menu-open-filter: grayscale(50%);

/* Media Breakpoints */
$from-sm-mobile: 350px;
$from-md-mobile: 400px;
$from-lg-mobile: 480px;
$from-tablet: 768px;
$from-laptop: 1152px;
$from-desktop: 1440px;
$from-wide: 1920px;
