/* CRA - bring in normalize.css styles */
// @import-normalize;

@import "./variables";

@font-face {
  font-family: "SignPainter HouseScript";
  src: local("SignPainter"), local("SignPainter HouseScript"),
    url("./fonts/SignPainter\ HouseScript\ Regular.ttf") format("truetype");
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* to disable auto scroll anchoring in supported browsers for e.g. testing */
  /* overflow-anchor: none; */
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  touch-action: manipulation;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  position: relative;
  background: #fff;
  text-align: center;
  color: $body-text;
  font-family: $font-default;
  font-size: $body-text-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: 200ms height cubic-bezier(0, 1, 0, 1); /* This is to make mobile screen resizes smoother */
}

b,
strong {
  font-weight: 600;
}

button,
pre {
  font-family: $font-default; /* overrides some browsers default */
}

a {
  cursor: pointer;
}

select,
textarea,
input {
  font-size: 16px; /* on iOS Safari we need at least 16px to stop from zooming in on input */
  line-height: 19px;
}

input,
textarea,
.allowTextSelection,
.allowTextSelection * {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

main {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  min-width: 320px;
  z-index: 0;
  display: table;
  table-layout: fixed;
  height: inherit;
}

h1,
h2 {
  font-weight: 600;
}

h3 {
  font-weight: 500;
}

h4 {
  font-weight: 400;
}

.force-emoji {
  font-family: $font-emoji;
}

.funky {
  font-family: "SignPainter HouseScript";
}

@keyframes phase {
  0% {
    background-position: 0 0;
  }
  25% {
    background-position: 20%;
  }
  50% {
    background-position: 100% 0;
  }
  75% {
    background-position: 20%;
  }
  100% {
    background-position: 0 0;
  }
}

@media (min-width: $from-tablet) {
  * {
    -webkit-touch-callout: default;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
}
