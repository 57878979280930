@import "../../../../style/variables";

.PopupEmailVerifyUsingCode {
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: $body-text;
    margin-top: 15px;
    margin-bottom: 25px;
  }
  .resending {
    position: relative;
    width: 100%;
    height: 140px;
    color: $primary;
    .text {
      margin-top: 22px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      color: $body-text;
    }
  }
  .content {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: $body-text;
      margin: 0;
    }
    b {
      font-weight: bold;
    }
    hr {
      border-color: #e8e8e8;
    }
    .fieldWrapper {
      margin-top: 25px;
      label {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: $body-text;
      }
      input {
        background: $white;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: $black;
        text-align: left;
        border-radius: 6px;
        padding: 12px;
        margin-top: 10px;
        border: 1px solid $border-color;
        width: 100%;
        &::placeholder {
          color: #a7adb7;
          opacity: 1; /* Firefox */
        }
      }
    }
    .suggestionWrapper {
      margin: 20px 0;
    }
    .suggestion {
      display: flex;
      margin-top: 16px;
      margin-bottom: 20px;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      p {
        font-weight: 500;
      }
      button {
        font-weight: 700;
      }
    }
    form {
      .codeWrapper {
        margin-top: 6px;
        margin-bottom: 20px;
        .codeBox {
          margin-right: 15px;
          &:last-child {
            margin-right: 0;
          }
          input {
            width: 40px;
            height: 40px;
            background: transparent;
            border: 1px solid rgba(0, 0, 0, 0.4);
            border-radius: 6px;
            font-family: monospace;
            font-size: 24px;
            text-align: center;
            padding: 0;
          }
        }
      }
    }
    .buttonWrapper {
      margin-top: 15px;
    }
    .separator {
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: 20px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: $body-text;
      &::before,
      &::after {
        content: "";
        flex: 1;
        border-bottom: 1px solid #e8e8e8;
      }
      &::before {
        margin-right: 6px;
      }
      &::after {
        margin-left: 6px;
      }
    }
  }
  .validationWrapper {
    div {
      margin-top: 22px;
      text-align: center;
    }
  }
}
