@import "../style/variables";

.ClearCacheUnban {
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  .container {
    display: inline-block;
    .text {
      margin: 10px 0;
      font-size: 14px;
      line-height: 19px;
      color: $button-green;
    }
  }
}
